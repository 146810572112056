import { tw } from '@/utils/tw';
import BaseHighlightedArticlesTheme from 'base/components/HighlightedArticles/theme';

const Blip = tw.theme({
  extend: BaseHighlightedArticlesTheme.Blip,
});

const Icon = tw.theme({
  extend: BaseHighlightedArticlesTheme.Icon,
});

const Skeleton = tw.theme({
  extend: BaseHighlightedArticlesTheme.Skeleton,
  base: `h-[69px] md:h-[45px]`,
});

const HighlightedArticles = tw.theme({
  extend: BaseHighlightedArticlesTheme,
  variants: {
    variant: {
      primary: {
        base: `h-[69px] md:h-[45px]`,
        item: `bg-light-sand-500`,
        headline: `text-preview-sm md:text-preview-sm`,
        label: `text-preview-sm md:text-preview-sm !font-medium text-black`,
      },
    },
  },
});

export default Object.assign(HighlightedArticles, { Icon, Blip, Skeleton });
