import { tw } from '@/utils/tw';
import BaseAuthorDetailsTheme from 'base/components/AuthorDetails/theme';

const { Image, SoMe } = BaseAuthorDetailsTheme;

const AuthorDetails = tw.theme({
  extend: BaseAuthorDetailsTheme,
  variants: {
    variant: {
      default: {
        base: ['bg-light-sand-500', 'p-3', 'gap-x-6', 'm-6', 'mb-0', 'w-auto', 'max-w-none', 'rounded-none'],
        caption: ['text-preview-lg', 'sm:text-preview-sm', 'sm:my-2', 'md:my-0'],
        description: ['max-w-2xl', 'text-paragraph-md', 'mt-1', 'mb-3', 'sm:mt-3'],
        email: ['font-medium', 'text-preview-lg', 'mt-2'],
        headline: ['text-gabor-lg', 'sm:text-gabor-md', 'mb-0.5', 'sm:mb-1', 'mt-4'],
        soMeGroup: ['gap-x-3', 'mt-3', 'mb-3', 'sm:mb-1.5'],
      },
    },
  },
});

export default Object.assign(AuthorDetails, { Image, SoMe });
