import { Button } from '@/components/Button';
import { OptionalLink } from '@/components/OptionalLink';
import { Component } from '@/types/component';
import NextImage from 'next/image';
import React, { useState, useRef } from 'react';
import { MemberOffersModal } from './MemberOffers.Modal';
import { MemberOffersModalContent } from './MemberOffers.Modal.Content';

export const placeholderImage =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAATQAAADrCAQAAABNl6JaAAABtElEQVR42u3SQQ0AAAjEMM6/UlSAChIerYRl6Sk4F6NhNIwGRsNoGA2MhtHAaBgNo4HRMBoYDaNhNDAaRgOjYTSMBkbDaGA0jIbRwGgYDaOB0TAaGA2jYTQwGkYDo2E0jAZGw2hgNIyG0cBoGA2MhtEwGhgNo2E0MBpGA6NhNIwGRsNoYDSMhtHAaBgNjIbRMBoYDaOB0TAaRgOjYTSMBkbDaGA0jIbRwGgYDYyG0TAaGA2jgdEwGkYDo2E0MBpGw2hgNIyG0cBoGA2MhtEwGhgNo4HRMBpGA6NhNDAaRsNoYDSMBkbDaBgNjIbRMBoYDaOB0TAaRgOjYTQwGkbDaGA0jAZGw2gYDYyG0cBoGA2jgdEwGkYDo2E0MBpGw2hgNIwGRsNoGA2MhtHAaBgNo4HRMBoYDaNhNDAaRsNoYDSMBkbDaBgNjIbRwGgYDaOB0TAaGA2jYTQwGkYDo2E0jAZGw2gYDYyG0cBoGA2jgdEwGhgNo2E0MBpGA6NhNIwGRsNoYDSMhtHAaBgNo4mA0TAaGA2jYTQwGkYDo2E0jAZGw2hgNIyG0cBoGA2MhtEwGhiNxxZ0rcDzi2N9KwAAAABJRU5ErkJggg==';

export interface Offer {
  img?: string;
  offerText?: string;
  buttonText?: string;
  offerCode: string;
  offerLink?: string;
  offerLinkText?: string;
  offerFooterText?: string;
}

export interface ElleStandaloneMemberOffersProps {
  title: string;
  offers: Offer[];
}

export const ElleStandaloneMemberOffers: Component<ElleStandaloneMemberOffersProps> = ({ title, offers }) => {
  const [activeOffer, setActiveOffer] = useState<Offer | null>(null);
  const modalRef = useRef<HTMLDialogElement>(null);

  if (offers.length === 0) return null;

  const openOfferModal = (offer: Offer) => {
    setActiveOffer(offer);
    modalRef.current?.showModal();
  };

  const closeOfferModal = () => {
    setActiveOffer(null);
    modalRef.current?.close();
  };

  const clickModalDialog = ({ target, currentTarget }: React.MouseEvent<HTMLDialogElement>) => {
    if (target === currentTarget) {
      closeOfferModal();
    }
  };

  return (
    <div className="mt-5">
      <div className="relative w-full after:absolute after:left-0 after:top-1/2 after:-z-1 after:h-px after:w-full after:-translate-y-1/2 after:bg-black">
        <h3 className="mb-1.5 inline-block bg-white pr-2 text-headline-xs md:mb-2.5 md:pr-3 md:text-headline-md">
          {title || 'Mina erbjudanden'}
        </h3>
      </div>
      <div className="flex-wrap -mx-2 flex">
        {offers.map((offer: Offer) => {
          const { img, offerCode, offerLink, offerText, buttonText } = offer;

          const imageComponent = (
            <NextImage src={img || placeholderImage} alt="" width={308} height={233} className="mx-auto" />
          );

          return (
            <div key={offerCode} className="mb-9 flex w-1/2 flex-col flex-wrap px-2 md:w-1/4">
              <div className="mb-3 max-h-[233px] w-full overflow-hidden md:mb-4">
                <OptionalLink
                  target="_blank"
                  href={offerLink && !buttonText ? offerLink : undefined}
                  content={imageComponent}
                  options={{ rel: 'nofollow' }}
                />
              </div>
              {offerText && (
                <p className="mb-2 flex grow items-center justify-center text-center text-preview-sm md:mb-4 md:text-preview-lg">
                  {offerText}
                </p>
              )}
              {buttonText && (
                <Button
                  options={{
                    className: 'max-w-full mx-auto pt-2.25 pb-1.25 md:px-12 md:pt-3.75 md:pb-2.75',
                    onClick: () => openOfferModal(offer),
                  }}
                  content={buttonText}
                />
              )}
            </div>
          );
        })}
      </div>
      <MemberOffersModal ref={modalRef} onClick={clickModalDialog} className="backdrop:bg-black/50">
        {activeOffer && <MemberOffersModalContent activeOffer={activeOffer} onCloseModal={closeOfferModal} />}
      </MemberOffersModal>
    </div>
  );
};
