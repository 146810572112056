import { tw } from '@/utils/tw';
import BaseSaveArticleTheme from 'base/components/SaveArticle/theme';

const Button = tw.theme({
  extend: BaseSaveArticleTheme.Button,
  defaultVariants: {
    colors: 'secondary',
  },
});

const SaveArticle = tw.theme({
  extend: BaseSaveArticleTheme,
  slots: {
    base: ['order-8', 'col-span-2', 'sm:col-span-1'],
    modal: ['[&::backdrop]:bg-black/50', 'p-0'],
  },
});

export default Object.assign(SaveArticle, { Button });
