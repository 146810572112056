import { tw } from '@/utils/tw';
import BaseAuthorsListTheme from 'base/components/AuthorsList/theme';

const ListItemImage = tw.theme({
  extend: BaseAuthorsListTheme.ListItemImage,
  slots: {
    base: ['hidden'],
    group: ['w-0'],
  },
});

const AuthorsListTheme = tw.theme({
  extend: BaseAuthorsListTheme,
  slots: {
    base: ['m-6'],
    headline: ['text-gabor-lg'],
    description: ['text-preview-lg'],
    listItem: ['w-full', 'sm:w-88'],
    listItemTitle: ['text-preview-lg'],
  },
  variants: {
    colors: {
      default: { listItem: ['bg-light-sand-500', 'hover:bg-light-sand-900', 'active:bg-light-sand-500'] },
    },
  },
});

export default Object.assign(AuthorsListTheme, {
  ListItemLink: BaseAuthorsListTheme.ListItemLink,
  ListItemImage,
  ListItemIcon: BaseAuthorsListTheme.ListItemIcon,
});
