import { tw } from '@/utils/tw';
import BaseContentLockTheme from 'base/components/ContentLock/theme';

const ContentLockTheme = tw.theme({
  extend: BaseContentLockTheme,
  slots: {
    base: ['lg:min-w-[824px]', 'not-prose', 'md:left-1/2', 'md:-translate-x-1/2'],
    loginCaption: ['text-preview-sm'],
    loginFooter: ['text-button-md', 'hover:underline', 'hover:text-yves-klein-900'],
    loginHeadline: ['text-gabor-lg'],
  },
});

export default Object.assign(ContentLockTheme, { Button: BaseContentLockTheme.Button });
