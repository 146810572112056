import { tw } from '@/utils/tw';
import DialogTheme from 'base/components/Dialog/theme';

const CloseButton = tw.theme({
  extend: DialogTheme.CloseButton,
  base: ['absolute', 'right-3', 'top-3', 'max-h-6', 'w-6', 'px-0', 'pt-0.5'],
});

const CloseIcon = tw.theme({
  extend: DialogTheme.CloseIcon,
  slots: {
    base: ['fill-white', 'mx-auto'],
  },
});

const DialogIcon = tw.theme({
  extend: DialogTheme.DialogIcon,
});

const Dialog = tw.theme({
  extend: DialogTheme,
  slots: {
    base: ['backdrop:bg-black/60', 'bg-light-sand-500'],
    buttonGroup: ['[&>button]:max-w-none'],
  },
});

export default Object.assign(Dialog, { DialogIcon, CloseIcon, Button: DialogTheme.Button, CloseButton });
