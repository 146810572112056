import { tw } from '@/utils/tw';
import BaseSavedArticlesTheme from 'base/components/SavedArticles/theme';

const { Button } = BaseSavedArticlesTheme;

const Icon = tw.theme({
  extend: BaseSavedArticlesTheme.Icon,
});

const Image = tw.theme({
  extend: BaseSavedArticlesTheme.Image,
});

const Tag = tw.theme({
  extend: BaseSavedArticlesTheme.Tag,
  variants: {
    size: { text: `text-tag-md` },
    colors: { text: `text-gray-800 ring-transparent` },
  },
});

const SavedArticles = tw.theme({
  extend: BaseSavedArticlesTheme,
  slots: {
    headline: `text-gabor-sm md:text-gabor-md pr-3 after:hidden`,
    empty: `text-preview-lg [&_a]:font-medium`,
    articleCaption: `pt-1.5 text-headline-xs md:text-headline-sm`,
    articleDescription: `text-preview-sm pt-2`,
  },
});

export default Object.assign(SavedArticles, { Button, Icon, Image, Tag });
