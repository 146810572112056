import { tw } from '@/utils/tw';
import BaseRecommendedArticlesTheme from 'base/components/RecommendedArticles/theme';

const Skeleton = tw.theme({
  extend: BaseRecommendedArticlesTheme.Skeleton,
  base: ['h-[340px]'],
});

const ArticleTeaser = tw.theme({
  extend: BaseRecommendedArticlesTheme.ArticleTeaser,
  slots: {
    group: ['gap-1'],
    caption: ['text-tag-md'],
  },
});

const RecommendedArticles = tw.theme({
  extend: BaseRecommendedArticlesTheme,
  slots: {
    base: ['relative', 'wings', 'py-4', 'md:py-6', 'px-6'],
    headline: [
      'inline',
      'before:-z-1',
      'before:bg-yves-klein-900',
      'before:block',
      'before:h-px',
      'before:relative',
      'before:top-3',
      'bg-white',
      'mb-0',
      'md:text-gabor-md',
      'pr-3',
      'relative',
      'text-gabor-sm',
      'uppercase',
      'z-0',
    ],
    list: ['gap-6', 'mt-4.5'],
  },
});

export default Object.assign(RecommendedArticles, { ArticleTeaser, Skeleton });
